const ReadNewsCardNew = ({ postData }) => {
  var pageToOpen = postData && postData?.permalink?.split(".com")[1];


  function trimSentenceToWords(sentence, wordLimit) {
      // Split the sentence into words
      const words = sentence.split(' ');
  
      // Check if the number of words is more than the limit
      if (words.length > wordLimit) {
          // Slice the array to keep only the first 'wordLimit' words
          const trimmedWords = words.slice(0, wordLimit);
  
          // Join the trimmed words back into a sentence
          return trimmedWords.join(' ') + '...';
      }
  
      return sentence;
  }
  console.log("readNewsNEw", postData);
  return (
    <a href={pageToOpen}>
      <div className="news-article-small">
        <div className="news-image" style={{position:"relative"}}>
          <div className="type-article"></div>
          <img
            src={postData?.headingImageUrl + "?width=200"}
            className="article-image"
          />
          <div className="news-type-icon" style={{ color: "white",position:"absolute",bottom:"2px" ,left:"4px"}}>
              {postData.eventStatus === undefined && (
                <i
                  className={`icon-${
                    postData?.type === "text" ||
                    postData.type === "liveblog"
                      ? "cat-read"
                      : postData?.type === "audio"
                      ? "mic"
                      : "play-circle"
                  }`}
                ></i>
              )}
          </div>
        </div>
        <div className="news-text">{trimSentenceToWords(postData?.title,17)}</div>
      </div>
    </a>
  );
};
export default ReadNewsCardNew;
