import {
  CATEGORY_GET_INIT,
  SET_SELECTED_TAB,
  SUBCATEGORY_INIT_DATA,
} from "redux/actionTypes/category.actionTypes";

//category actions

export const getCategory = () => {
  return { type: CATEGORY_GET_INIT };
};

export const setSelectedTab = (payload) => {
  return { type: SET_SELECTED_TAB , payload };
};


// subCategory items
export const subCategoriesItems = (payload) => {
  return { type: SUBCATEGORY_INIT_DATA, payload };
};
