import {
  CATEGORY_GET_FAILED,
  CATEGORY_GET_INIT,
  CATEGORY_GET_SUCCESS,
  CATEGORY_INIT,
  SET_SELECTED_TAB,
  SUBCATEGORY_FAILED_DATA,
  SUBCATEGORY_INIT_DATA,
  SUBCATEGORY_SUCCESS_DATA,
} from "redux/actionTypes/category.actionTypes";

const iniitialState = {
  category1: {categories: []},
  error: false,
  message: "",
  subCategoryItem: [],
  selectedTab : null
};

export default function CategoryReducer(state = iniitialState, action) {
  switch (action.type) {
    // Do something here based on the different types of actions


    case SET_SELECTED_TAB :
      return {
        ...state,
        selectedTab : action.payload
      }
    case CATEGORY_INIT:
      return {
        ...state,
        ...action.payload,
      };

    

    case CATEGORY_GET_INIT:
      return {
        ...state,
        ...action.payload,
      };

    case CATEGORY_GET_SUCCESS:
      return {
        ...state,
        ...action.payload,
        category1: action.payload.entity,
        error: false,
      };
    case CATEGORY_GET_FAILED:
      return {
        ...state,
        ...action.payload,
        message: action.payload.message,
        error: true,
      };
    // categoriesItems
    case SUBCATEGORY_INIT_DATA:
      return {
        ...state,
        loading: true,
        message: "",
        subCategoryItem: !action.payload._id ? [] : state.subCategoryItem
      };
    case SUBCATEGORY_SUCCESS_DATA:
      return {
        ...state,
        error: false,
        loading: false,
        message: action.payload.message,
        subCategoryItem: action.payload.entity,
      };
    case SUBCATEGORY_FAILED_DATA:
      return {
        ...state,
        error: true,
        message: action.payload.message,
        loading: false,
      };

    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state;
  }
}
